import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	inject,
	signal,
} from '@angular/core'
import {
	NavigationEnd,
	NavigationStart,
	Router,
	RouterModule,
} from '@angular/router'
import { EnvironmentService } from '../../../../../environments/environment.service'
import { Subject, Subscription, takeUntil } from 'rxjs'
import { AREA, AREACONFIG } from '../../../../constants/areas.constants'
import { AreasService } from '../../../../../environments/areas.service'
import { ITab } from '../../../../shared/models/tab'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MenuItem } from 'primeng/api/menuitem'
import { CommonModule, Location, NgOptimizedImage } from '@angular/common'
import newNotification from '../../../home/components/navbar/navbar.component'
import { UserPermissionConfig } from '../../../authorization/roles/roles-config'
import { MenubarModule } from 'primeng/menubar'
import { TieredMenuModule } from 'primeng/tieredmenu'
import { MenuModule } from 'primeng/menu'
import { DropdownModule } from 'primeng/dropdown'
import { FormsModule } from '@angular/forms'

export var disableNavbar = signal(false)

@Component({
	selector: 'app-backoffice-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss', './navbar-mobile.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		RouterModule,
		MenubarModule,
		TieredMenuModule,
		MenuModule,
		DropdownModule,
		FormsModule,
		CommonModule,
		NgOptimizedImage,
	],
})
export class NavbarComponent implements OnInit {
	private apiAreasService = inject(AreasService)
	private apiEnvironmentService = inject(EnvironmentService)

	private routerSubscription: Subscription | undefined

	public disableNavbar = disableNavbar
	public activeTab: string = ''
	public allowedTabs: MenuItem[] = []
	public userNameInitials: string = ''
	public invalidInitials: boolean = false

	public backofficeHomeUrl: string = '/backoffice/monitoring'
	public requestsUrl: string = '/backoffice/requests'
	public tripsUrl: string = '/backoffice/trips'

	private userRoles: string[] = []
	private userPermissions: string[] = []
	private destroy$ = new Subject<void>()
	public selectedLanguage!: { label: string; code: string }
	public languages: { label: string; code: string }[] = [
		{ label: 'Português', code: 'pt-PT' },
		{ label: 'English', code: 'en-UK' },
	]

	items: MenuItem[] | undefined
	// Tabs to not show
	notShowTabs: string[] = ['monitoring']
	isDriver: boolean = false

	constructor(
		private translate: TranslateService,
		private router: Router,
	) {
		// Get roles and permissions
		this.userRoles = this.apiEnvironmentService.getUser()?.roles || []
		this.userPermissions =
			this.apiEnvironmentService.getUser()?.permissions || []
	}

	logout() {
		this.apiEnvironmentService.logout()
		this.router.navigate(['/auth/login'])
		this.disableNavbar.set(true)
	}

	//checks for changes in the language of the menu
	checkLang() {
		this.translate
			.get('navBar')
			.pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
				this.items = [
					{
						label: '',
						value: null,
					},

					{
						label: res.settings,
						icon: 'pi pi-cog',
						command: () => {
							this.router.navigate(['/profile'])
						},
					},
					{
						label: res.logout,
						icon: 'pi pi-sign-out',
						command: () => {
							this.logout()
						},
					},
				]
				this.items[0].style = { display: 'none' }
			})
	}

	ngOnInit(): void {
		let allowedTabs = this.showTabs()
		this.getUserNameInitials()

		this.selectedLanguage =
			this.translate.currentLang === 'pt-PT'
				? this.languages[0]
				: this.languages[1]
		this.translate.onLangChange.subscribe(() => {
			this.selectedLanguage = this.languages.find(
				(lan) => lan.code === this.translate.currentLang,
			)!
		})

		this.routerSubscription = this.router.events.subscribe((event: any) => {
			this.activeTab = event.routerEvent?.url
			if (this.activeTab === '/backoffice') {
				this.activeTab = '/backoffice/monitoring'
			}
			if (event instanceof NavigationStart) {
				this.activeTab = event.url
				if (this.activeTab === '/backoffice') {
					this.activeTab = '/backoffice/monitoring'
				}

				this.getUserNameInitials()
			}
			if (event instanceof NavigationEnd) {
				// Set active tab to current route
				this.activeTab = event.url

				// Show tabs based on user permissions
				allowedTabs = this.showTabs()
			}
		})
		allowedTabs = allowedTabs.filter((item) => item.label != 'monitoring')
		this.allowedTabs = allowedTabs

		this.checkRole()
	}

	// Get username initials in uppercase
	getUserNameInitials() {
		this.userNameInitials = this.apiEnvironmentService
			.getUser()
			?.name.split(' ')
			.map((n: string) => n[0])
			.join('')
			.toLocaleUpperCase()!
		if (
			this.userNameInitials == undefined ||
			this.userNameInitials.length <= 0 ||
			this.userNameInitials.length > 2
		) {
			this.invalidInitials = true
		}

		return this.userNameInitials
	}

	// Check if tab is active
	isActive(tab: string): boolean {
		return this.activeTab === tab
	}
	isParentActive(tab: MenuItem): boolean {
		for (let item of tab.items!) {
			if (this.isActive(item['route'])) {
				return true
			}
		}
		return false
	}

	// Get tabs that user can access through permissions
	showTabs() {
		const areas = this.apiAreasService.getAvailableAreas()
		const allowedTabs = areas.flatMap((area) => {
			if (area.code == AREA.AUTH) return []

			// Check permissions from all subareas and map the allowed ones to the template
			const subAreas = area.subAreas?.map((suba) => {
				var newRoute: string | undefined
				if (suba.route != undefined)
					newRoute =
						'/' +
						suba.route?.split('/')[1] +
						'/' +
						suba.route?.split('/')[2]
				return {
					parent: true,
					label: suba.name.toLocaleLowerCase(),
					route: newRoute,
					permission: suba.permission,
					items: suba.subAreas?.map((suba) => {
						if (suba.route != undefined)
							newRoute =
								'/' +
								suba.route?.split('/')[1] +
								'/' +
								suba.route?.split('/')[2]

						return {
							label: suba.name.toLocaleLowerCase(),
							permission: suba.permission,
							route: newRoute,
						}
					}),
				} as MenuItem
			})
			return subAreas ? subAreas : []
		})

		// Return list of allowed tabs
		return allowedTabs
	}

	ngOnDestroy(): void {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe()
		}
		this.destroy$.next()
	}

	onLanguageChange() {
		this.translate.use(this.selectedLanguage.code)
		this.checkLang() //to refresh the menu
		localStorage.setItem('language', this.selectedLanguage.code)
	}

	newNotification() {
		return newNotification()
	}

	checkRole() {
		// Only admin contains this permission
		const adminPermission =
			this.apiEnvironmentService.checkUserContainsAllPermissions([
				UserPermissionConfig.EditVehicle,
			])

		if (!adminPermission) {
			this.isDriver = true
		} else {
			this.isDriver = false
		}
	}
}
