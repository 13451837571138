<nav class="navbar-container" *ngIf="!disableNavbar()">
	<!-- LOGO -->
	<div class="flex logos-container">
		<a class="navbar-logo" [routerLink]="backofficeHomeUrl">
			<img
				ngSrc="../../../assets/Logo_cb.svg"
				alt="{{ 'alt.CBLogo' | translate }}"
				class="py-1"
				width="200"
				height="50"
				priority
			/>
		</a>
		<a class="navbar-logo" [routerLink]="backofficeHomeUrl">
			<img
				class="navbar-logo h-full"
				ngSrc="../../../assets/Mobicab_Logo.png"
				alt="Mobicab Logo"
				class="py-1"
				width="200"
				height="50"
				priority
			/>
		</a>
	</div>

	<div class="flex menu-container">
		<div class="desktop-menu" [class.driver-desktop-menu]="isDriver">
			<p-menubar
				[model]="allowedTabs"
				[autoDisplay]="true"
				responsive="true"
				#menubar
				appendTo="body"
			>
				<ng-template pTemplate="item" let-item>
					@if (!notShowTabs.includes(item.label.toLowerCase())) {
						<ng-container *ngIf="item.route; else noLink">
							<a
								pRipple
								class="flex align-items-center p-menuitem-link"
								[routerLink]="item.route"
								[class.current]="isActive(item.route)"
							>
								{{ 'navBar.' + item.label | translate }}
							</a>
						</ng-container>
						<ng-template #noLink>
							<a
								pRipple
								class="flex align-items-center p-menuitem-link"
								[routerLink]="item.route"
								[class.current]="isParentActive(item)"
							>
								{{ 'navBar.' + item.label | translate }}
							</a>
						</ng-template>
					}
				</ng-template>
			</p-menubar>
		</div>

		<div
			class="flex flex-row h-full navbar-user-options"
			[class.driver-user-options]="isDriver"
		>
			<!-- HAMBURGER MENU -->
			<div class="mobile-menu" *ngIf="!isDriver">
				<p-tieredMenu
					[model]="allowedTabs"
					[popup]="true"
					#popupMenu
					class="mobile-menu"
				>
					<ng-template
						pTemplate="item"
						let-item
						let-hasSubmenu="hasSubmenu"
					>
						@if (
							!notShowTabs.includes(item.label.toLowerCase()) &&
							item.route != '/backoffice/monitoring'
						) {
							<ng-container *ngIf="item.route; else noLink">
								<a
									class="p-menuitem-link"
									[routerLink]="item.route"
									[class.current]="isActive(item.route)"
								>
									{{ 'navBar.' + item.label | translate }}
								</a>
							</ng-container>
							<ng-template #noLink>
								<a
									class="p-menuitem-link"
									[routerLink]="item.route"
									[class.current]="isParentActive(item)"
								>
									{{ 'navBar.' + item.label | translate }}
									<span
										*ngIf="hasSubmenu"
										class="pi pi-angle-right ml-auto"
									></span>
								</a>
							</ng-template>
						}
					</ng-template>
				</p-tieredMenu>
				<div
					class="navbar-user circular-bg navbar-op"
					(click)="popupMenu.toggle($event)"
				>
					<a
						class="navbar-user-link"
						style="
							display: flex;
							align-items: center;
							justify-content: center;
						"
					>
						<i class="pi pi-bars"></i>
					</a>
				</div>
			</div>

			<!-- NOTIFICATIONS -->
			<div>
				<div class="navbar-notifications circular-bg navbar-op">
					<a class="navbar-notification" href="notifications">
						<i
							class="fa-solid fa-bell"
							*ngIf="!newNotification()"
						></i>
						<i
							class="fa-solid fa-bell"
							pBadge
							*ngIf="newNotification()"
						></i>
					</a>
				</div>
			</div>

			<!-- USER -->
			<div>
				<p-menu
					#menu
					[model]="items"
					[popup]="true"
					(onShow)="checkLang()"
				>
					<ng-template pTemplate="end">
						<p-dropdown
							[options]="languages"
							[(ngModel)]="selectedLanguage"
							optionLabel="label"
							(onChange)="onLanguageChange()"
							[placeholder]="selectedLanguage.label"
						>
							<ng-template pTemplate="selectedItem">
								<span>
									<img
										[ngSrc]="
											'../../../assets/flags/' +
											selectedLanguage.code +
											'.svg'
										"
										style="
											height: 1rem;
											margin-right: 0.5rem;
										"
										width="50"
										height="50"
									/>
									<p>{{ selectedLanguage.label }}</p>
								</span>
							</ng-template>
							<ng-template pTemplate="item" let-option>
								<span>
									<img
										[ngSrc]="
											'../../../assets/flags/' +
											option.code +
											'.svg'
										"
										style="
											height: 1rem;
											margin-right: 0.5rem;
										"
										width="50"
										height="50"
									/>
									<p>{{ option.label }}</p>
								</span>
							</ng-template>
						</p-dropdown>
					</ng-template>
				</p-menu>
				<div
					class="navbar-user circular-bg navbar-op"
					(click)="menu.toggle($event)"
				>
					<a class="navbar-user-link">
						@if (!invalidInitials) {
							<p id="initials">
								{{ userNameInitials }}
							</p>
						} @else {
							<i class="pi pi-user"></i>
						}
					</a>
				</div>
			</div>
		</div>
	</div>
</nav>
<div class="spacer" *ngIf="!disableNavbar()"></div>
<div *ngIf="isDriver" class="driver-mobile-menu">
	<div
		[class.current-mobile]="isActive('/backoffice/requests')"
		[routerLink]="requestsUrl"
	>
		{{ 'navBar.requests' | translate }}
	</div>
	<div
		[class.current-mobile]="isActive('/backoffice/trips')"
		[routerLink]="tripsUrl"
	>
		{{ 'navBar.trips' | translate }}
	</div>
</div>
